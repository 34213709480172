import MenuIcon from '@mui/icons-material/Menu';
import { useState } from 'react';

export const Menu = () => {
  const [isActive, setIsActive] = useState<boolean>(false);

  const showMenu = () => {
    // console.log(window.innerWidth);
    if (window.innerWidth >= 992) return;
    setIsActive(!isActive);
  };

  const click = (id: string) => {
    const element = document.getElementById(id);
    element?.scrollIntoView({
      behavior: 'smooth'
    });
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark ftco-navbar-light" id="ftco-navbar">
      <div className="container">
        <button
          onClick={showMenu}
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#ftco-nav"
          aria-controls="ftco-nav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="oi oi-menu"></span> <MenuIcon /> Menu
        </button>
        <div className={`collapse navbar-collapse ${isActive ? 'show' : ''}`} id="ftco-nav">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item active">
              <button onClick={() => click('destacadas')} className="nav-link btn-link">
                Destacadas
              </button>
            </li>
            <li className="nav-item">
              <button onClick={() => click('propiedades')} className="nav-link btn-link">
                Propiedades
              </button>
            </li>
            <li className="nav-item">
              <button onClick={() => click('section-contacto')} className="nav-link btn-link">
                Contacto
              </button>
            </li>
            <li className="nav-item">
              <button onClick={() => click('agentes')} className="nav-link btn-link">
                Nuestro Equipo
              </button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
