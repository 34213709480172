import styled from 'styled-components';

import { rojoRemax } from './const';
// Col 1 más adentro
// Row 5 la más arriba
export const Box = styled.div<{ $col: number; $row: number; $size: number; $alpha: number }>`
  position: absolute;
  bottom: ${props => props.$size * (props.$row - 1)}px;
  right: ${props => props.$size * (props.$col - 1)}px;
  width: ${props => props.$size}px;
  height: ${props => props.$size}px;
  background-color: rgba(255, 255, 255, ${props => props.$alpha}); /* Color blanco semitransparente */
  border-radius: 0px;
  z-index: 3; /* Asegura que esté por encima del overlay */
`;

export const ReservadoDiv = styled.div`
  display: flex;
  justify-content: space-between; /* Distribuye los elementos con espacio entre ellos */
  align-items: center; /* Alinea los elementos verticalmente al centro */
  width: 100%; /* Asegura que ocupe el 100% del espacio disponible */
  position: initial;
  margin-bottom: -29px;
`;

export const ReservadoSpan = styled.span`
  background: ${rojoRemax};
  position: initial;
  padding: 2px 15px;
  color: #fff;
`;
