import { FC, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import 'react-multi-carousel/lib/styles.css';
import { Box } from '../styled/intro';

const Intro: FC = () => {
  const [sizeBox, setSizeBox] = useState<number>(75);

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const isTablet = useMediaQuery({ query: '(min-width: 769px) and (max-width: 1024px)' });
  const isDesktop = useMediaQuery({ query: '(min-width: 1025px)' });

  useEffect(() => {
    if (isMobile) {
      setSizeBox(30);
    } else if (isTablet) {
      setSizeBox(50);
    } else {
      setSizeBox(75);
    }
  }, [isMobile, isTablet, isDesktop]);

  return (
    <section className="video-container-intro main embed-responsive embed-responsive-16by9 video-height">
      <div className="overlay-intro"></div>
      <iframe
        // width="560"
        // height="315"
        className="iframe-intro"
        src="https://www.youtube.com/embed/CuEAFJmOE8w?playlist=CuEAFJmOE8w&autoplay=1&mute=1&loop=1&controls=0&rel=0&disablekb=1&fs=1"
        title="Remax Sur III"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
      <Box $alpha={0.6} $col={1} $row={4} $size={sizeBox} />

      <Box $alpha={0.6} $col={2} $row={3} $size={sizeBox} />
      <Box $alpha={0.8} $col={1} $row={3} $size={sizeBox} />

      <Box $alpha={0.6} $col={5} $row={2} $size={sizeBox} />
      <Box $alpha={0.6} $col={4} $row={2} $size={sizeBox} />
      <Box $alpha={0.85} $col={3} $row={2} $size={sizeBox} />
      <Box $alpha={1} $col={2} $row={2} $size={sizeBox} />
      <Box $alpha={1} $col={1} $row={2} $size={sizeBox} />

      <Box $alpha={0.6} $col={6} $row={1} $size={sizeBox} />
      <Box $alpha={0.85} $col={5} $row={1} $size={sizeBox} />
      <Box $alpha={1} $col={4} $row={1} $size={sizeBox} />
      <Box $alpha={1} $col={3} $row={1} $size={sizeBox} />
      <Box $alpha={1} $col={2} $row={1} $size={sizeBox} />
      <Box $alpha={1} $col={1} $row={1} $size={sizeBox} />
    </section>
  );
};
export default Intro;
